import { defineNuxtPlugin } from 'nuxt/app'
import type { VueI18n } from 'vue-i18n';
import axios from 'axios'
import type { AxiosInstance, AxiosError } from 'axios'
import decodeToken from '@/helpers/decodeToken'
import checkJWTExpiry from '@/helpers/checkJWTExpiry'

export default defineNuxtPlugin((nuxtApp) => {
  const i18n = nuxtApp.$i18n as VueI18n
  const router = useRouter()

  const onAuthError = () => {
    router.push({ path: `/${i18n.t('login_path')}` })
  }

  const onResponseError = (error: AxiosError) => {
    ({
      401: onAuthError
    } as { [key: number]: () => void })?.[error.response?.status ?? 0]?.()
  
    return Promise.reject(error)
  }

  
  // Create an Axios instance
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: useRuntimeConfig().public.apiURL,
    validateStatus: function (status) {
      // Return `true` if the status code is less than 400, indicating a successful response
      return status < 400
    }
  })

  axiosInstance.interceptors.response.use(null, onResponseError)

  // Request interceptor to add the Authorization header to every request
  axiosInstance.interceptors.request.use(async (config) => {
    const { session, loadSession } = useSession()
    if (!session.value.token) {
      await loadSession()
    }

    if (session.value?.token) {
      let token = session.value.token
      const decodedToken = decodeToken(token)
      if (checkJWTExpiry(decodedToken)) {
        try {
          const { data } = await axios.get('/api/auth/session', { baseURL: window.location.origin })
          token = data.token
        } catch (error) {
          console.log('token refresh error')
          console.error(error)
        }
      }

      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  }, (error) => {
    return Promise.reject(error)
  })

  // Provide the configured axios instance to app
  nuxtApp.provide('axios', axiosInstance)
})
