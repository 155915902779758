export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('click-outside', {
    mounted (el, binding) {
      const startListening = () => {
        document.addEventListener('mousedown', el.clickOutsideEvent)
      }

      el.clickOutsideEvent = function (event) {
        if (!(el.contains(event.target))) {
          binding.value(event, binding.arg)
        }
      }
      setTimeout(startListening, 50)
    },
    beforeUnmount (el) {
      document.removeEventListener('mousedown', el.clickOutsideEvent)
    }
  })
})
