import revive_payload_client_4sVQNw7RlN from "/var/www/fml-webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/fml-webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/fml-webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/fml-webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/fml-webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/fml-webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/fml-webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/fml-webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/fml-webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_RZVNejKJBe from "/var/www/fml-webapp/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_AOuQ1DYzjk from "/var/www/fml-webapp/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/var/www/fml-webapp/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/var/www/fml-webapp/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import session_qoMCkkKHp8 from "/var/www/fml-webapp/plugins/session.ts";
import fontawesome_cn2c4tOOHz from "/var/www/fml-webapp/plugins/fontawesome.ts";
import vue_toast_notification_E63izwGIhW from "/var/www/fml-webapp/plugins/vue-toast-notification.ts";
import ClickOutside_x29SIzd9o8 from "/var/www/fml-webapp/plugins/ClickOutside.js";
import axios_QMFgzss1s4 from "/var/www/fml-webapp/plugins/axios.ts";
import element_icons_client_yfotTq4sse from "/var/www/fml-webapp/plugins/element-icons.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_RZVNejKJBe,
  plugin_AOuQ1DYzjk,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  session_qoMCkkKHp8,
  fontawesome_cn2c4tOOHz,
  vue_toast_notification_E63izwGIhW,
  ClickOutside_x29SIzd9o8,
  axios_QMFgzss1s4,
  element_icons_client_yfotTq4sse
]